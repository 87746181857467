<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230"
            content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img :src="user_picture"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px">
              <v-img :src="user_picture"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span class="text--primary font-weight-semibold mb-n1">
              {{ name }}
            </span>
            <small class="text--disabled text-capitalize">{{ position }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <v-list-item
          v-for="item in items"
          link
          v-if="position === 'ADMIN'"
          :key="item.id"
          @click="start_new_month_transaction(item.category, item.id, item.month_of_id)"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiReload }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start New Month {{ item.category }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>
        <!-- Settings -->
        <v-list-item link @click="can_upload = true" v-if="position != 'EMPLOYEE'">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Upload Memo's</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item link @click="is_change_password = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
    <v-dialog v-model="can_upload" persistent max-width="60%">
      <v-card>
        <v-card-text class="d-flex">
          <v-row>

            <v-col md="12" cols="12">
              <vue-croppie width="100%" cover ref="croppieRef" :enableOrientation="true"
                           :mouseWheelZoom="false" :boundary="{ width: 950, height: 450}"
                           :viewport="{ width:925, height:425, 'type':'square' }">
              </vue-croppie>
            </v-col>
            <v-col md="12" cols="12">
              <v-text-field class="mx-2" v-model="memos_title" label="SUBJECT OF MEMO" dense
                            outlined
                            :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col xs12 md6>
              <v-text-field class="mx-2" v-model="memos_date" label="DATE" dense
                            outlined
                            type="date"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col xs12 md6>
              <v-select
                class="mx-2"
                v-model="category_id"
                dense
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                :rules="rules.combobox_rule"
                outlined
              ></v-select>
            </v-col>
            <v-col md="12" cols="12">
              <!-- upload photo -->
              <div v-if="memos_title!='' && category_id!=''">
                <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
                  <v-icon class="d-sm-none">
                    {{ icons.mdiCloudUploadOutline }}
                  </v-icon>
                  <span class="d-none d-sm-block">Select photo</span>
                </v-btn>

                <input
                  ref="refInputEl"
                  type="file"
                  accept=".jpeg,.png,.jpg"
                  :hidden="true"
                  v-on:click="$refs.refInputEl.value = ''"
                  v-on:change="croppie"
                />

                <v-btn color="error" outlined class="mt-5" @click="resett()"> Reset</v-btn>
                <p class="text-sm mt-5">Allowed JPG or PNG</p>
              </div>
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      126009
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="upload_data" class="w-full"> Upload</v-btn>
        </v-card-actions>
      </v-card>

      <v-btn color="error" @click="can_upload = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiChatOutline,
    mdiCogOutline,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiContentSaveMove,
    mdiCloudUploadOutline,
  } from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ChangePassword from '@/views/dashboard/ChangePassword'

  export default {
    data() {
      return {
        alert: false,
        alert_message: '',

        show: false,
        can_upload: false,
        txt: '',
        memos_date: '',
        memos_title: '',
        category_id: '',
        category_items: [],
        user_picture: require('@/assets/images/avatars/1.png'),
        croppieImage: '',
        avatarImg: require('@/assets/images/misc/upload_cheque.jpg'),
        cropped: false,
        items: [],
        is_change_password: false,
        icons: {
          mdiChatOutline,
          mdiCogOutline,
          mdiCloudUploadOutline,
          mdiHelpCircleOutline,
          mdiLogoutVariant,
          mdiContentSaveMove,
        },
      }
    },
    mounted() {
      if (this.user_pic != '') {
        this.user_picture = this.user_pic
      }
      this.conditions_for_new_month()
      // this.generate_salary_scheme()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['id_no', 'name', 'position', 'month_of', 'user_pic', 'employee_id', 'already_generate_salary_scheme']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    components: {
      snackBarDialog,
      ChangePassword,
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('authentication', ['logout_credentials', 'set_already_generate_salary_schemeee']),
      ...mapActions('employee_compensation_history', ['head_officers_salary_increase_generate', 'employee_salary_increase_generate']),
      ...mapActions('start_new_month', [
        'is_can_new_month',
        'is_can_new_month_create_new_month',
        'is_can_new_month_create_bank_balances',
        'is_can_new_month_update_request',
      ]),
      ...mapActions('category', ['list_of_category_can_new_month', 'list_of_all_active_categories']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('uploaded_memos', ['upload_memos']),
      croppie(e) {
        this.cropped = false
        this.show = true
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = true
          this.$refs.croppieRef.bind({
            url: e.target.result
          });
        }
        reader.readAsDataURL(files[0])
        this.show = false
      },
      resett() {
        this.$refs.croppieRef.bind({
          url: this.avatarImg
        });
        this.$refs.refInputEl.value = ''
        this.cropped = false
        this.croppieImage = ''
        this.alert = false
        this.memos_title = ''
        this.memos_date = ''
      },
      async upload_data() {
        let options = {
          type: 'base64',
          format: 'jpeg'
        };
        await this.$refs.croppieRef.result(options, output => {
          this.croppieImage = output;
        });
        var proceed = true
        if (!this.cropped) {
          this.alert = true
          this.alert_message = 'Please Upload Photo'
          this.saving = false
          proceed = false
        }
        if (this.memos_title === '') {
          this.alert = true
          this.alert_message = 'Please Fill Up the Title of Memo'
          this.saving = false
          proceed = false
        }
        if (proceed) {
          const data = new FormData()
          data.append('category_id', this.category_id)
          data.append('employee_id_released', this.employee_id)
          data.append('subject', this.memos_title.toUpperCase())
          data.append('memos_date', this.memos_date)
          data.append('scan_cheque_location', this.croppieImage)
          this.upload_memos(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: response.status === 200 ? 'success' : 'error',
                text: response.data,
              })
              if (response.status === 200) {
                this.resett()
              }
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        }
      },
      conditions_for_new_month() {
        this.list_of_category_can_new_month()
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.initialize_salaries_employee()
          .then(response => {
            this.category_items = response.data[0].category
            this.category_items.splice(0, 0, {
              id: -1,
              category: 'GGC',
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      async start_new_month_transaction(category, category_id, month_of_id) {
        this.show = true
        this.txt = 'COLLECTING DATA..'
        var errorr = false
        await this.is_can_new_month({
          category: category,
        })
          .then(resolve => {
            if (resolve.data === 'OK') {
              this.txt = 'NEW MONTH ON PROCESS....'
              this.is_can_new_month_create_new_month({
                category_id: category_id,
              })
                .then(resolve => {
                  if (resolve.data === 'OK') {
                    this.txt = 'PROCEED TO BANK BALANCES....'
                    this.is_can_new_month_create_bank_balances({
                      category_id: category_id,
                      month_of_id: month_of_id,
                    })
                      .then(resolve => {
                        if (resolve.data === 'OK') {
                          this.txt = 'UPDATING UNUSED REQUEST....'
                          this.is_can_new_month_update_request({
                            category_id: category_id,
                            month_of_id: month_of_id,
                          })
                            .then(resolve => {
                              if (resolve.data === 'OK') {
                                this.txt = 'FINALIZING...'
                              } else {
                                this.txt = resolve.data
                                errorr = true
                              }
                            })
                            .catch(error => {
                              this.txt = error
                              errorr = true
                            })
                        } else {
                          this.txt = resolve.data
                          errorr = true
                        }
                      })
                      .catch(error => {
                        this.txt = error
                        errorr = true
                      })
                  } else {
                    this.txt = resolve.data
                    errorr = true
                  }
                })
                .catch(error => {
                  this.txt = error
                  errorr = true
                })
            } else {
              this.txt = resolve.data
              errorr = true
            }
          })
          .catch(error => {
            this.txt = error
            errorr = true
          })
        if (!errorr) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.txt = ''
            this.show = false
            // location.reload()
          }, 3000)
        }
      },
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
